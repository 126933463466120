import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../images/logo.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { LuBadgeInfo } from "react-icons/lu";
import { WholeContext } from "../App";
import { FaHeart } from "react-icons/fa6";
import { FaCartShopping } from "react-icons/fa6";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { state } = useContext(WholeContext);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleDownload = () => {
    window.open(
      "https://cdn.explorug.com/website/only1dollardesign/O1DD_catalog.pdf",
      "_blank"
    );
  };

  return (
    <nav className="nav">
      <Link to="/">
        <img src={logo} alt="logo" className="nav-logo" />
      </Link>

      <div className="hamburger" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>

      <ul className={`nav-items ${isOpen ? "nav-items-active" : ""}`}>
        <li>
          <button className="sampler-button" onClick={handleDownload}>
            Download Sampler
          </button>
        </li>
        {/* <li>
          <Link to="/about" className="nav-link">
            ABOUT US
          </Link>
        </li> */}
        <li>
          <Link to="/contact" className="nav-link">
            CONTACT
          </Link>
        </li>
        <li>
          <Link to="/favorites" className="nav-link">
            <FaHeart />
            <span>({state.favorites.length})</span>
          </Link>
        </li>
        <li>
          <Link to="/checkout" className="nav-link">
            <FaCartShopping />
            <span>({state.cart.length})</span>
          </Link>
        </li>
        <li ref={dropdownRef}>
          <LuBadgeInfo
            className="nav-badge"
            style={{ marginRight: "0.9rem" }}
            onClick={toggleDropdown}
          />
          {dropdownOpen && (
            <div className="dropdown">
              <ul>
                <li>
                  <Link
                    to="/faq"
                    className="nav-links"
                    onClick={() => setDropdownOpen(false)}
                  >
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link
                    to="/termsofuse"
                    className="nav-links"
                    onClick={() => setDropdownOpen(false)}
                  >
                    TERMS OF USE
                  </Link>
                </li>
              </ul>
            </div>
          )}
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
