import axios from "axios";
import { saveAs } from "file-saver";
import { getApiKey } from "../api/appProvider";
import { getDesignName } from "../utils/utils";

export const thumbDownload = async (selectedDesign) => {
  const apiKey = getApiKey();
  const randomSuffix = Math.floor(Math.random() * 900) + 100;
  const designName = getDesignName(selectedDesign);
  const filesArray = [`${selectedDesign}`];

  const formData = new FormData();
  formData.append("key", apiKey);
  formData.append("action", "zipdesignfor1dollar");
  formData.append("product", "o1dd");
  formData.append("name", `${designName}${randomSuffix}`);
  formData.append("files", JSON.stringify(filesArray));

  // console.log("FormData being sent to the server:");
  // for (let [key, value] of formData.entries()) {
  //   console.log(key + ": " + value);
  // }


  try {
    const response = await axios.post(
      "https://v3.explorug.com/appproviderv3.aspx",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const zipFilePath =
      typeof response.data === "string"
        ? response.data.trim()
        : response.data.path?.trim();

    if (!zipFilePath) {
      throw new Error("ZIP file path is missing in the response.");
    }

    const zipFileUrl = `https://v3.explorug.com${zipFilePath}`;

    const fileResponse = await axios.get(zipFileUrl, {
      responseType: "blob",
    });

    if (fileResponse.status !== 200) {
      throw new Error(
        "Failed to download the ZIP file from the provided URL."
      );
    }

    const blob = new Blob([fileResponse.data], { type: "application/zip" });
    saveAs(blob, `${designName}-O1DD.zip`);
  } catch (error) {
    console.error("Error while downloading the ZIP:", error.response || error.message);
    if (error.response) {
      console.log("Error response status:", error.response.status);
      console.log("Error response headers:", error.response.headers);
      console.log("Error response data:", error.response.data);
    } else {
      console.log("Error message:", error.message);
    }
  }
};
