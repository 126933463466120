import { getDesignName } from "../utils/utils";

export const toggleFavorite = (designPath, favorites, dispatch, imageUrl) => {
  const designName = getDesignName(designPath);
  const isAlreadyFavorite = favorites.some((fav) => fav.design === designPath);

  let updatedFavorites;
  if (isAlreadyFavorite) {
    updatedFavorites = favorites.filter((fav) => fav.design !== designPath);
    // console.log("Removed from favorites:", designName);
  } else {
    const newFavorite = {
      design: designPath,
      thumb: imageUrl,
    };
    updatedFavorites = [...favorites, newFavorite];
    // console.log("Added to favorites:", newFavorite);
  }

  dispatch({
    type: "set_favorites",
    payload: updatedFavorites,
  });

  window.localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
};
