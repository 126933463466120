import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Container,
  Row,
  Col,
  Modal,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import axios from "axios";

import DesignsFilter from "./DesignsFilter";
import DesignCategory from "./DesignCategory";
import DesignsList from "./DesignsList";
import Pagenav from "./Pagenav";
import FullDesign from "../components/FullDesign/index";

import { CurrentPageContext, DesignContext, WholeContext } from "../App";
import { useParams } from "react-router-dom";
import AppNewProvider from "../api/appProvider";
import DownloadButton from "./DownloadButton";
import clipboard from "../images/clipboard.png";
import christmas from "../images/chirstmas.jpg";
import { ArrowsContainer } from "./StyledComponents";
let APIdomain = AppNewProvider.domain;
const DesignsPage = (props) => {
  const checkoutContext = useContext(WholeContext);
  const designContext = useContext(DesignContext);
  const [tooltipTitle, setTooltipTitle] = useState("Copy to Clipboard");
  const [token, setToken] = useState("");
  let selectDesign = designContext.selectDesign;
  const params = useParams();
  const [modal, setModal] = useState(false);
  // if (params && params.designname) {
  //   console.log("DesignsPage -> params", params.designname);
  // }

  let selectedDesign = checkoutContext.state.selectedDesign;

  useEffect(() => {
    const designList = checkoutContext.state.designList;
    if (!designList.length) return;
    if (!params.designname) return;
    const urlDesignname = params.designname.replace(":", "");
    const initdesign = designList.filter(
      (design) => design.Name.toLowerCase() === urlDesignname.toLowerCase()
    );
    if (initdesign) {
      AppNewProvider.fetchDesignThumbNails({
        designsFullPathlist: [initdesign[0].FullPath],
      }).then((selectedDesignThumbInfo) => {
        if (selectedDesignThumbInfo && selectedDesignThumbInfo.length) {
          const selectedFile = selectedDesignThumbInfo[0];
          checkoutContext.dispatch({ type: "set_BusySignal", payload: true });
          const Name = selectedFile.Name;
          const thumb = `${APIdomain}${selectedFile.Thumb}`;
          const props = selectedFile.Props;
          selectDesign(Name, thumb, props);
        }
      });
    }
  }, [checkoutContext.state.designList]);

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function () {
      var formData = new FormData();
      formData.append("product", "only1dollardesign");
      formData.append("event", "initialLoad");
      fetch("https://explorug.com/archanastools/Utilities/WebsiteLog.aspx", {
        method: "POST",
        body: formData,
      });

      axios
        .get("https://explorug.com/archanastools/Utilities/GetCoupon.aspx")
        .then((res) => {
          setToken(res.data.coupon);
        });
    });
  }, []);

  function postToPage(e) {
    const galainchaurl =
      "https://explorug.com/archanastools/Utilities/WebsiteLog.aspx";
    var formdata = new FormData();
    formdata.append("product", "only1dollardesign");
    formdata.delete("event", e.target.getAttribute("data-event"));
    formdata.append("event", e.target.getAttribute("data-event"));
    fetch(galainchaurl, {
      method: "POST",
      body: formdata,
    });
  }
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltipTitle}
    </Tooltip>
  );

  const copyToClipboard = () => {
    var copyText = document.getElementById("tokenValue");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
    setTooltipTitle("Copied successfully");
    setTimeout(() => {
      setTooltipTitle("Copy to Clipboard");
    }, [2000]);
    // document.getElementById("button-tooltip ").innerHTML = "Copied Successfully";
  };

  const currentPageContext = useContext(CurrentPageContext);
  const handlePageChange = currentPageContext.handlePageChange;

  const wholeContext = useContext(WholeContext);
  let currentPage = wholeContext.state.currentPage;
  let disPatch = wholeContext.dispatch;

  const handleClick = (direction) => {
    disPatch({ type: "set_BusySignal", payload: true });
    handlePageChange(direction);
  };

  return (
    <div>
      <Container style={{ marginTop: 30 }}>
      <Helmet>
        <title>Only 1Dollar Design</title>
        <link rel="canonical" href="https://only1dollardesign.com/" />
      </Helmet>
        {/* TO enable modal, update state modal to true in above useState */}
        <Modal
          show={modal}
          onHide={() => setModal(false)}
          size="lg"
          centered
          className="initial-pop-up"
        >
          <span
            aria-hidden="true"
            className="close-icon"
            onClick={() => setModal(false)}
          >
            &times;
          </span>
          {/* Christmas post */}
          {/* <video
            src="https://cdn.explorug.com/Website/Galaincha/black-friday/BlackFriday2023a.mp4"
            autoPlay
            loop
            muted
            style={{ position: "relative" }}
          /> */}

          {/* Christmas post */}
          {/* <img src={christmas} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            <div id="tokenDisplay" style={{ marginBottom: "1rem" }}>
              <input
                type="text"
                disabled
                value={token}
                id="tokenValue"
                style={{ textAlign: "center" }}
              />
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <img
                  src={clipboard}
                  style={{ cursor: "pointer", marginLeft: "0.5rem" }}
                  onClick={copyToClipboard}
                  width="20px"
                />
              </OverlayTrigger>
            </div>
            <p id="redeemText">
              Redeem your{" "}
              <span style={{ color: "#672E92", fontWeight: "900" }}>$10</span>{" "}
              worth prepaid coupon during checkout in{" "}
              <a
                href="http://only1dollardesign.com/"
                target="_blank"
                style={{ color: "#672e92", fontWeight: "bolder" }}
              >
                ONLY1DollarDesign
              </a>
              {"."}
            </p>
          </div> */}
          {/* BlackFriday post */}
          {/* <video
            src="https://cdn.explorug.com/Website/Galaincha/black-friday/BlackFriday2023a.mp4"
            autoPlay
            loop
            muted
            style={{ position: "relative" }}
          />
          <a
            data-event="blackFriday, Only1dollar"
            target="_blank"
            onClick={(e) => postToPage(e)}
            href="https://only1dollardesign.com/"
            style={{
              position: "absolute",
              bottom: "0",
              left: "13%",
              width: "13%",
              height: "10%",
              background: "transparent",
              border: "none",
              cursor: "pointer",
            }}
          ></a>
          <a
            data-event="blackFriday, OnlyHundred"
            target="_blank"
            onClick={(e) => postToPage(e)}
            href="https://onlyhundred.com/"
            style={{
              position: "absolute",
              bottom: "0",
              left: "43%",
              width: "20%",
              height: "10%",
              background: "transparent",
              border: "none",
              cursor: "pointer",
            }}
          ></a>
          <a
            target="_blank"
            data-event="blackFriday, RamroDesigns"
            onClick={(e) => postToPage(e)}
            href="https://ramrodesigns.com/"
            style={{
              position: "absolute",
              bottom: "0",
              left: "75%",
              width: "14%",
              height: "10%",
              border: "none",
              background: "transparent",
              cursor: "pointer",
            }}
          ></a> */}
        </Modal>
        <Row
          style={{
            padding: "4rem 1rem",
            paddingBottom: "0",
            justifyContent: "space-between",
          }}
        >
          <Col xs={8}>
            <DesignsFilter />
          </Col>
          <Col className="design-catagory-wrapper" xs="auto">
            <DesignCategory />
          </Col>
        </Row>
        <DesignsList />
        {/* <ArrowsContainer>
          <div className={currentPage > 0 ? null : "disabled"} id="leftarrow">
            <span onClick={() => handleClick("prev")}>‹</span>
          </div>
          <div id="rightarrow">
            <span onClick={() => handleClick("next")}>›</span>
          </div>
        </ArrowsContainer> */}
        {selectedDesign !== "" ? <FullDesign /> : null}
      </Container>
    </div>
  );
};

export default DesignsPage;
