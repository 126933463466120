import React, { useContext, useEffect, useState } from "react";
import { DesignsArea, DesignThumb } from "./StyledComponents";
import BusySignal from "./BusySignal";
import { DesignContext, WholeContext } from "../App";
import { BsCartCheckFill } from "react-icons/bs";
import { MdShoppingCartCheckout } from "react-icons/md";
import { FaDownload } from "react-icons/fa6";
import AppNewProvider from "../api/appProvider";
import { GoHeart, GoHeartFill } from "react-icons/go";
import { getDesignName } from "../utils/utils";
import { toggleFavorite } from "../utils/favourite";
import UtilitiesFn from "../functions/UtilitiesFn";
import Pagenav from "./Pagenav";
import { thumbDownload } from "../functions/thumbDownload";

let APIdomain = AppNewProvider.domain;

const DesignsList = () => {
  const designContext = useContext(DesignContext);
  const selectDesign = designContext.selectDesign;

  const wholeContext = useContext(WholeContext);
  const thumbs = wholeContext.state.designThumbs;
  const showContentLoadingSignal = wholeContext.state.showContentLoadingSignal;
  const favorites = wholeContext.state.favorites || [];
  const cart = wholeContext.state.cart || [];
  const dispatch = wholeContext.dispatch;

  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const storedFavorites =
      JSON.parse(window.localStorage.getItem("favorites")) || [];
    dispatch({ type: "set_favorites", payload: storedFavorites });
  }, [dispatch]);

  const handleClick = (e) => {
    const selectedDesign = e.target.getAttribute("data-name");
    const selectedThumb = e.target.getAttribute("src");
    wholeContext.dispatch({ type: "set_BusySignal", payload: true });
    selectDesign(selectedDesign, selectedThumb);
  };

  const handleFavoriteToggle = (designPath, imageUrl) => {
    toggleFavorite(designPath, favorites, dispatch, imageUrl);
  };

  const handleAddToCart = (selectedDesign, imageUrl) => {
    return new Promise((resolve) => {
      const alreadyInCart = UtilitiesFn.alreadyInCart(selectedDesign, cart);
      if (!alreadyInCart) {
        addDesignToCart(selectedDesign, imageUrl).then(() => {
          resolve(true);
        });
      } else {
        resolve(true);
      }
    });
  };

  const addDesignToCart = (selectedDesign, imageUrl) => {
    return new Promise((resolve) => {
      const designCart = [...cart, { design: selectedDesign, thumb: imageUrl }];
      dispatch({ type: "set_cart", payload: designCart });
      window.localStorage.setItem("cart", JSON.stringify(designCart));
      resolve();
    });
  };

  const handleCart = (selectedDesign, imageUrl) => {
    handleAddToCart(selectedDesign, imageUrl).then((added) => {
      if (added) {
        dispatch({ type: "set_inCart", payload: true });
      }
    });
  };

  const isComplimentaryCollection = (designName) => {
    const trimmedName = designName.split("/")[1].replace(/\.ctf$/, "");
    return trimmedName === "Complimentary Collection";
  };

  const handleDownload = (designName) => {
    setIsDownloading(true);  
    thumbDownload(designName)  
      .finally(() => {
        setIsDownloading(false);  
      });
  };

  return (
    <div style={{ padding: "2rem" }}>
      <DesignsArea>
        <Pagenav />
        <BusySignal show={showContentLoadingSignal || isDownloading} />
        {thumbs.length > 0
          ? thumbs.map((thumb, index) => {
              {/* const designName = getDesignName(thumb.Name);   */}
              const imageUrl = `${APIdomain}${thumb.Thumb}`;
              const isFavorite = favorites.some(
                (fav) => fav.design === thumb.Name
              );
              const alreadyInCart = UtilitiesFn.alreadyInCart(thumb.Name, cart);

              const isComplimentary = isComplimentaryCollection(thumb.Name);

              return (
                <DesignThumb
                  key={index}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={10}
                  className="thumbs"
                >
                  <div>
                    <img
                      data-name={thumb.Name}
                      onClick={handleClick}
                      src={imageUrl}
                      alt={thumb.Name}
                    />
                    {isComplimentary ? (
                      <div
                        className="icon cart-icon"
                        onClick={() => handleDownload(thumb.Name)} 
                      >
                        <FaDownload size={20} />
                      </div>
                    ) : (
                      <div
                        className="icon cart-icon"
                        onClick={() => handleCart(thumb.Name, imageUrl)}
                      >
                        {alreadyInCart ? (
                          <BsCartCheckFill size={20} />
                        ) : (
                          <MdShoppingCartCheckout size={20} />
                        )}
                      </div>
                    )}
                    <div
                      className="icon heart-icon"
                      onClick={() => handleFavoriteToggle(thumb.Name, imageUrl)}
                    >
                      {isFavorite ? (
                        <GoHeartFill size={20} color="purple" />
                      ) : (
                        <GoHeart size={20} />
                      )}
                    </div>
                  </div>
                </DesignThumb>
              );
            })
          : null}
      </DesignsArea>
    </div>
  );
};

export default DesignsList;
