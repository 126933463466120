import React, { useContext, useState, useEffect } from "react";
import { DesignContext } from "../App";
import { WholeContext } from "../App";
import "./DesignCategory.css";

const DesignCategory = () => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [expandedDescription, setExpandedDescription] = useState(null);
  const filterContext = useContext(DesignContext);
  const selectCategory = filterContext.selectCategory;

  const wholeContext = useContext(WholeContext);
  const filters = wholeContext.state.designCategories;
  const selectedFilters = wholeContext.state.selectedFilters;

  const filterDescriptions = {
    Abstract:
      "Our Abstract Collection features an affordable range of color-separated designs, ideal for a variety of products. These ready-to-use, versatile abstract designs are crafted for seamless application, adding an artistic touch to your items and elevating them effortlessly.",

    Border:
      "Explore border designs like never before with our unique collection of patterns and motifs. Redefining traditional borders, these creative and innovative designs bring a fresh, standout look to any product.",

    "Complimentary Collection":
      "We provide a Complementary Set to enhance the value of your purchase. This set is thoughtfully designed to perfectly complement your main design, offering a variety of elements, colors, and design styles completely free of charge. It’s our commitment to making your design experience both cost-effective and exceptional!",

    "Designers Collection":
      "A thoughtfully curated collection featuring a selected range of patterns chosen by designers, each reflecting their unique creative vision, style, and craftsmanship. These designs bring an artistic touch to a variety of products, ensuring each piece stands out with its distinct designer flair.",

    Repeat:
      "Browse our extensive collection of repeat patterns, perfect for a wide variety of products. Find affordable, versatile designs that bring a cohesive and harmonious look to multiple items, ensuring seamless flow and style for numerous ranges of products.",

    All: "In the “ALL” section, you’ll find a comprehensive collection of every style, pattern, and creative theme. Whether you're looking for traditional, modern, abstract, or custom-inspired designs, this section provides endless inspiration From repeat patterns to unique styles, all available at affordable prices. With so many options, you’re sure to find the perfect design to suit any space, aesthetic, or product.",
  };

  const handleClick = (categorySelected) => {
    let filterList = [...selectedFilters];

    if (categorySelected.toLowerCase() === "all") {
      filterList = [];
    } else if (selectedFilters.includes(categorySelected)) {
      filterList = filterList.filter(
        (item) => item.toLowerCase() !== categorySelected.toLowerCase()
      );
    } else {
      filterList.push(categorySelected);
    }

    selectCategory(filterList);
  };

  const toggleDropdown = () => {
    setDropdownVisible((prevState) => !prevState);
  };

  const toggleDescription = (category) => {
    setExpandedDescription((prev) => (prev === category ? null : category));
  };

  useEffect(() => {
    if (isDropdownVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isDropdownVisible]);

  return (
    <div>
      <div
        id="category"
        onClick={toggleDropdown}
        className={isDropdownVisible ? "open" : ""}
      >
        CATEGORY
      </div>

      {isDropdownVisible && (
        <div className="dropdown-container">
          <div
            key="All"
            value="All"
            onClick={() => handleClick("All")}
            className={`all-option ${
              selectedFilters.length === 0 ? "filteroptionsactive" : ""
            }`}
          >
            <div className="filter-content">
              <span>All</span>
              <span
                className={`custom-checkbox ${
                  selectedFilters.length === 0 ? "checked" : ""
                }`}
              ></span>
            </div>
            <p className="filter-description">
              {expandedDescription === "All"
                ? filterDescriptions["All"]
                : `${filterDescriptions["All"].substring(0, 50)}... `}

              <span
                className="read-more"
                onClick={() => toggleDescription("All")}
              >
                {expandedDescription === "All" ? "Show Less" : "Read More"}
              </span>
            </p>
          </div>

          {filters.length > 0 &&
            filters.map((filter, index) => (
              <div
                key={index}
                value={filter.FullPath}
                onClick={() => handleClick(filter.FullPath)}
                className={`filter-option ${
                  selectedFilters.includes(filter.FullPath)
                    ? "filteroptionsactive"
                    : ""
                }`}
              >
                <div className="filter-content">
                  <span>{filter.Name}</span>
                  <span
                    className={`custom-checkbox ${
                      selectedFilters.includes(filter.FullPath) ? "checked" : ""
                    }`}
                  ></span>
                </div>

                {filterDescriptions[filter.Name] && (
                  <p className="filter-description">
                    {expandedDescription === filter.Name
                      ? filterDescriptions[filter.Name]
                      : `${filterDescriptions[filter.Name].substring(
                          0,
                          50
                        )}... `}

                    <span
                      className="read-more"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleDescription(filter.Name);
                      }}
                    >
                      {expandedDescription === filter.Name
                        ? "Show Less"
                        : "Read More"}
                    </span>
                  </p>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default DesignCategory;
